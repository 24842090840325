import { OFFWHITE } from "../../common/style/colors";

export const mainStyle = {
  background: "rgba(0,0,0,0.8)",
  fontSize: "20px",
  width: "80vw",
  margin: "auto",
  display: "flex",
  flexDirection: "column" as "column",
  color: OFFWHITE,
};
