import React from "react";
import { AboutUsComponent } from "./aboutUsComponent";

export default function AboutUsIndex(props: {}) {
  return (
    <>
      <AboutUsComponent></AboutUsComponent>
    </>
  );
}
