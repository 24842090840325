import { Icon } from "@mui/material";

export function SocialMediaIcon(props: {
  iconPath: string;
  url: string;
  altText: string;
}) {
  return (
    <>
      <Icon style={{ color: "white" }}>
        <a href={props.url} target="_blank" rel="noopener noreferrer">
          <img src={props.iconPath} alt={props.altText} />
        </a>
      </Icon>
    </>
  );
}

export function TwitterIcon() {
  return (
    <>
      <SocialMediaIcon
        iconPath="/assets/socialMediaIcons/twitter.svg"
        url="https://twitter.com/furcotra"
        altText="Link to twitter"
      ></SocialMediaIcon>
    </>
  );
}

// export function TumblrIcon() {
//   return (
//     <>
//       <SocialMediaIcon
//         iconPath="/assets/socialMediaIcons/tumblr.svg"
//         url="https://furcotra.tumblr.com/"
//         altText="Link to tumblr"
//       ></SocialMediaIcon>
//     </>
//   );
// }

// export function InstagramIcon() {
//   return (
//     <>
//       <SocialMediaIcon
//         iconPath="/assets/socialMediaIcons/instagram.svg"
//         url="https://www.instagram.com/furcotra"
//         altText="Link to instagram"
//       ></SocialMediaIcon>
//     </>
//   );
// }

export function TelegramIcon() {
  return (
    <>
      <SocialMediaIcon
        iconPath="/assets/socialMediaIcons/telegram.svg"
        url="https://t.me/+7OWhapp9qJo0Zjkx"
        altText="Link to telegram"
      ></SocialMediaIcon>
    </>
  );
}

// export function DiscordIcon() {
//   return (
//     <>
//       <SocialMediaIcon
//         iconPath="/assets/socialMediaIcons/discord.svg"
//         url="https://discord.gg/Wujk3NqN"
//         altText="Link to discord"
//       ></SocialMediaIcon>
//     </>
//   );
// }

export function FurAffinityIcon() {
  return (
    <>
      <SocialMediaIcon
        iconPath="/assets/socialMediaIcons/furaffinity.svg"
        url="https://www.furaffinity.net/user/furcotra/"
        altText="Link to furAffinity"
      ></SocialMediaIcon>
    </>
  );
}

// export function RedditIcon() {
//   return (
//     <>
//       <SocialMediaIcon
//         iconPath="/assets/socialMediaIcons/reddit.svg"
//         url="https://www.reddit.com/r/furcotra/"
//         altText="Link to reddit"
//       ></SocialMediaIcon>
//     </>
//   );
// }
