export default function CardBuilderInfo() {
  return (
    <div style={{ padding: "10px", width: "250px" }}>
      <h1>Instructions</h1>
      <p>1. Upload the art for your card</p>
      <p>2. Enter your card's information</p>
      <p>3. Click Submit</p>
      <p>
        4. Resize and reposition your art by clicking and dragging. Click
        anywhere on the card when you are done.
      </p>
      <p>5. Download your card</p>
      <p>6. Share it with the world!</p>
      <br></br>
    </div>
  );
}
