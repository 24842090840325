import { TextField, capitalize, Stack, Box } from "@mui/material";
import { ChangeEvent, Dispatch, useReducer } from "react";
import { CardModelPartialState } from "../../../common/cardModel";
import { statElements } from "../cardBuilderConfig";
import {
  StatError,
  statErrorDefault,
  statErrorReducer,
} from "../cardBuilderReducers";

export function StatForm(props: {
  setFormInput: Dispatch<CardModelPartialState>;
}) {
  const { setFormInput } = props;
  const [statErrors, setStatErrors] = useReducer(
    statErrorReducer,
    statErrorDefault
  );
  // Statbar handler
  const handleStatbarInput = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const newValue = parseInt(event.target.value, 10);
    if (newValue < 1 || newValue > 6 || isNaN(newValue)) {
      setStatErrors({ [name]: "Enter a number 1-6" });
      return;
    }
    setStatErrors({ [name]: "" });
    setFormInput({ [name]: newValue });
  };

  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
      >
        <Stack>
          <div>
            {statElements.map(({ elementName }) => {
              return (
                <TextField
                  key={"cardbuilder-form-" + elementName}
                  id={"cardbuilder-form-" + elementName}
                  error={statErrors[elementName as keyof StatError].length > 0}
                  label={capitalize(elementName)}
                  name={elementName}
                  type="number"
                  helperText={statErrors[elementName as keyof StatError]}
                  onChange={handleStatbarInput}
                  inputProps={{ min: 1, max: 6 }}
                ></TextField>
              );
            })}
          </div>
        </Stack>
      </Box>
    </>
  );
}
