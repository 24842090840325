export enum FormCategory {
  Anthro = "ANTHRO",
  Beast = "BEAST",
}

export enum Aesthetic {
  lovely = "lovely",
  natural = "natural",
  sporty = "sporty",
  trash = "trash",
  cyber = "cyber",
  // Creepy = "Creepy",
  celestial = "celestial",
  basic = "basic",
}

export interface StatEnumInterface {
  pwr: number;
  int: number;
  cte: number;
  wrd: number;
  col: number;
  chr: number;
  power: number;
  intelligence: number;
  cuteness: number;
  weirdness: number;
  coolness: number;
  charmisma: number;
}

export enum StatEnum {
  pwr = "pwr",
  int = "int",
  cte = "cte",
  wrd = "wrd",
  col = "col",
  chr = "chr",
  power = "pwr",
  intelligence = "int",
  cuteness = "cte",
  weirdness = "wrd",
  coolness = "col",
  charmisma = "chr",
}

export interface StatIdxInterface {
  pwr: number;
  int: number;
  cte: number;
  wrd: number;
  col: number;
  chr: number;
}

export const StatIdx = {
  pwr: 0,
  int: 1,
  cte: 2,
  wrd: 3,
  col: 4,
  chr: 5,
};

export interface Stat {
  statName: StatEnum;
  statValue: number;
}

export interface CardModel {
  name: string;
  bodyshape: string;
  // aesthetics: Aesthetic[];
  species: string;
  vibe: string;
  gender: string;
  quote: string;
  power: number;
  intelligence: number;
  cuteness: number;
  weirdness: number;
  coolness: number;
  charmisma: number;
  // setId: number;
  // inSetId: number;
  // id: number;
  // rarity: number;
  illustrator: string;
  aesthetic: string;
  cardArt: string; // URL to art image
  characterOwner: string;
  readonly baseImage: string;
  readonly baseComponent: string;
}

export interface CardModelPartialState {
  name?: string;
  bodyshape?: string;
  // aesthetics: Aesthetic[];
  species?: string;
  vibe?: string;
  gender?: string;

  // stats: Stat[];
  quote?: string;
  // setId: number;
  // inSetId: number;
  // id: number;
  // rarity: number;
  characterOwner?: string;
  illustrator?: string;
  cardArt?: string;
  power?: number;
  intelligence?: number;
  cuteness?: number;
  weirdness?: number;
  coolness?: number;
  charmisma?: number;
  aesthetic?: string;
}
