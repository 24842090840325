import React from "react";
import TopBannerComponent from "./topBannerComponent/topBannerComponent";
import { Outlet } from "react-router-dom";
import { NavBarComponent } from "./navBarComponent/navBarComponent";
import { Box } from "@mui/system";
import { mainStyle } from "./mainStyle";
import { DARKER_GRAY } from "../../common/style/colors";

function MainComponent(props: {}) {
  return (
    <div style={mainStyle}>
      <div className="grid grid-cols-5">
        <TopBannerComponent></TopBannerComponent>
        <NavBarComponent></NavBarComponent>
      </div>
      <Box
        sx={{
          margin: "15px",
          padding: "15px",
          backgroundColor: DARKER_GRAY,
          flexGrow: 1,
        }}
      >
        <Outlet></Outlet>
      </Box>
      <Box style={{ textAlign: "center", fontSize: "16px" }}>
        <p style={{ margin: "4px" }}>© Furcotra LLC 2022</p>
      </Box>
    </div>
  );
}

export default MainComponent;
