import { CardModel } from "../../../common/cardModel";
import {
  buildAsyncElementsToRender,
  buildSyncElementsToRender,
  Canvas,
} from "./cardBuilderCanvas";

export function CardBuilderDisplay(props: {
  cardState: CardModel;
  fanMode: boolean;
}) {
  return (
    <>
      <Canvas
        asyncElementsToRender={buildAsyncElementsToRender({
          ...props.cardState,
        })}
        syncElementsToRender={buildSyncElementsToRender({
          ...props.cardState,
        })}
      ></Canvas>
    </>
  );
}
