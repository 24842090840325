import {
  Box,
  Button,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, useContext, useReducer, useState } from "react";
import { capitalize } from "../../../common/utils/commonUtils";
import { cardBuilderDefaultState, textElements } from "../cardBuilderConfig";
import { CardBuilderContext } from "../cardBuilderContexts";
import { cardBuilderStateReducer } from "../cardBuilderReducers";
import { StatForm } from "./statForm";

export function CardBuilderForm() {
  // Local copy of form input state stored until submit
  const [formInput, setFormInput] = useReducer(
    cardBuilderStateReducer,
    cardBuilderDefaultState
  );

  const [state, dispatch] = useContext(CardBuilderContext);

  // Text handler
  const handleInput = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    const name = event.target.name;
    const newValue = event.target.value;
    setFormInput({ [name]: newValue });
  };

  // image upload handler
  const handleImageInput = (file: File) => {
    let pattern = /image-*/;

    if (!file.type.match(pattern)) {
      alert("Please upload an image");
      return;
    }

    const newValue = URL.createObjectURL(file);
    setFormInput({ cardArt: newValue });
  };

  // On submit, dispatch new CardBuilderState
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    debugger;
    event?.preventDefault();
    dispatch(formInput);
  };
  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          margin: "5px",
          width: "60ch",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "5px",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <Button
              variant="contained"
              component="label"
              sx={{ backgroundColor: "black" }}
            >
              <span style={{ margin: "5px" }}>Upload card art</span>
              <input
                type="file"
                accept="image/*"
                onChange={(event) => {
                  handleImageInput(event.target.files![0]);
                }}
              />
            </Button>

            <div>
              {textElements.map(({ elementName, formAttrs }) => {
                if (elementName !== "bodyshape") {
                  return (
                    <TextField
                      key={"cardbuilder-form-" + elementName}
                      name={elementName}
                      id={"cardbuilder-form-" + elementName}
                      label={capitalize(elementName)}
                      onChange={handleInput}
                      {...formAttrs}
                    ></TextField>
                  );
                }
              })}
            </div>
            <StatForm setFormInput={setFormInput}></StatForm>
            <div key={"bodyshape" + "-div"}>
              <InputLabel
                id={"bodyshape" + "-label"}
                key={"bodyshape" + "-label"}
              >
                {capitalize("bodyshape")}
              </InputLabel>
              <Select
                name={"bodyshape"}
                labelId={"bodyshape" + "-label"}
                key={"cardbuilder-form-" + "bodyshape"}
                id={"cardbuilder-form-" + "bodyshape"}
                value={formInput["bodyshape"]}
                label="Bodyshape"
                onChange={handleInput}
              >
                <MenuItem value={"ANTHRO"}>Anthro</MenuItem>
                <MenuItem value={"FERAL"}>Feral</MenuItem>
              </Select>
            </div>
            <div key={"aesthetic" + "-div"}>
              <InputLabel
                id={"aesthetic" + "-label"}
                key={"aesthetic" + "-label"}
              >
                {capitalize("aesthetic")}
              </InputLabel>
              <Select
                name={"aesthetic"}
                labelId={"aesthetic" + "-label"}
                id={"cardbuilder-form-" + "aesthetic"}
                key={"aesthetic-select"}
                value={formInput["aesthetic"]}
                label="aesthetic"
                onChange={handleInput}
              >
                {[
                  "basic",
                  "natural",
                  "lovely",
                  "sporty",
                  "celestial",
                  "cyber",
                  "trash",
                ].map((aesthetic) => {
                  return (
                    <MenuItem
                      key={"cardbuilder-form-" + aesthetic}
                      value={aesthetic}
                    >
                      {capitalize(aesthetic)}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Stack>
        </form>
      </Box>
    </>
  );
}
