/* istanbul ignore file */
import { ElementPosition } from "./elementPosition";
const fabric = require("fabric").fabric;

export interface TextAttrs {
  fontFamily: string;
  fontSize: number;
  fontWeight?: string;
}

export async function addText(
  canvas: any,
  text: string,
  position: ElementPosition,
  textAttrs: TextAttrs
) {
  let canvasTextAttrs = {
    left: position.topleft_x,
    top: position.topleft_y + position.height * 0.5,
    width: position.width,
    height: position.height,
    editable: false,
    lockMovementY: true,
    lockMovementX: true,
    lockRotation: true,
    lockScalingFlip: true,
    lockScalingX: true,
    lockScalingY: true,
    lockSkewingX: true,
    lockSkewingY: true,
    fixedWidth: position.width,
    fixedHeight: position.height,
    originY: "center",
    ...textAttrs,
  };
  let textObj = new fabric.Textbox(text, canvasTextAttrs);
  canvas.add(textObj);
  // Scale font size down if it's too big
  while (
    textObj.width > textObj.fixedWidth ||
    textObj.height > textObj.fixedHeight
  ) {
    canvasTextAttrs.fontSize -= 1;
    canvas.remove(textObj);
    textObj = new fabric.Textbox(text, canvasTextAttrs);
    canvas.add(textObj);
  }
  return Promise.resolve(); // Return blank promise to keep interface consistent with async canvas elements
}
