import React from "react";
import { ContactComponent } from "./contactComponent";

export default function ContactIndex(props: {}) {
  return (
    <>
      <ContactComponent></ContactComponent>
    </>
  );
}
