import { CardModel, CardModelPartialState } from "../../common/cardModel";

export function cardBuilderStateReducer(
  state: CardModel,
  newState: CardModelPartialState
): CardModel {
  return {
    ...state,
    ...newState,
  };
}

export interface StatError {
  power: string;
  intelligence: string;
  cuteness: string;
  weirdness: string;
  coolness: string;
  charmisma: string;
}

export interface PartialStatError {
  power?: string;
  intelligence?: string;
  cuteness?: string;
  weirdness?: string;
  coolness?: string;
  charmisma?: string;
}
export const statErrorDefault: StatError = {
  power: "",
  intelligence: "",
  cuteness: "",
  weirdness: "",
  coolness: "",
  charmisma: "",
};

export function statErrorReducer(
  state: StatError,
  newState: PartialStatError
): StatError {
  return {
    ...state,
    ...newState,
  };
}
