export function capitalize(mystr: string) {
  return mystr[0].toUpperCase() + mystr.slice(1);
}

/**
 * Thank you stackoverflow https://stackoverflow.com/a/61256158/2486357
 * @param array
 * @param callback
 */
/* istanbul ignore next */ // its a pain to test lol
export async function asyncForEach<T>(
  array: Array<T>,
  callback: (item: T, index: number) => Promise<void>
) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index);
  }
}
