/**
 * This class represents the position of a component on the card.
 */
export class ElementPosition {
  topleft_x: number;
  topleft_y: number;
  bottomright_x: number;
  bottomright_y: number;
  height: number;
  width: number;

  constructor(
    topleft_x: number,
    topleft_y: number,
    bottomright_x: number,
    bottomright_y: number
  ) {
    this.topleft_x = topleft_x;
    this.topleft_y = topleft_y;
    this.bottomright_x = bottomright_x;
    this.bottomright_y = bottomright_y;
    this.width = bottomright_x - topleft_x;
    this.height = bottomright_y - topleft_y;
  }
}
