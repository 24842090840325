/* istanbul ignore file */
import { Aesthetic, CardModel } from "../../common/cardModel";
import { ElementPosition } from "./card_builder_utils/elementPosition";
// import { TextAttrs } from "./card_builder_utils/textUtils";

// Images
export const BASE_POSITION = new ElementPosition(0, 0, 1028, 1408);
export const CARDART_POSITION = new ElementPosition(118, 226, 900, 803);
export const AESTHETIC_POSITION = new ElementPosition(850, 60, 930, 140);

// Statbar definitions
export const SPACE_BETWEEN_STAT_BARS = 21;
export const MAX_STAT_VALUE = 6;
export const TOP_BAR_STARTING_X = 733;
export const TOP_BAR_STARTING_Y = 893;
export const STAT_BAR_HEIGHT = 40;
export const MIDPIECE_WIDTH = 30;
export const ENDPIECE_WIDTH = 32;

export const CANVAS_SCALE = 0.5;

const BASE_PATH = "/assets/card_builder_assets/base_colors/basic_base.png";
const COMPONENTS_PATH =
  process.env.NODE_ENV === "development"
    ? "/assets/card_builder_assets/official_base_components.png"
    : "/assets/card_builder_assets/fan_base_components.png";
const PLACEHOLDER_CARDART_PATH = "/assets/badger.jpeg";

export interface CanvasElement {
  elementName: string;
  elementPosition: ElementPosition;
}

export interface TextElement {
  elementName: string;
  elementPosition: ElementPosition;
  textAttrs: any; // TODO: textattrs type
  formAttrs: any;
  getDisplayElement: (elementValue: string) => string;
}

export interface ImageElement {
  elementName: string;
  elementPosition: ElementPosition;
  selectable: boolean; // Whether or not the image can be moved around the canvas by the user
  getImageURL: (cardData: CardModel) => string;
}

export interface StatElement {
  elementName: string;
}

export const imageElements: ImageElement[] = [
  {
    elementName: "cardArt" as keyof Pick<CardModel, "cardArt">,
    elementPosition: CARDART_POSITION,
    selectable: true,
    getImageURL: (cardData: CardModel) => {
      return cardData["cardArt"];
    },
  },
  {
    elementName: "baseImage" as keyof Pick<CardModel, "baseImage">,
    elementPosition: BASE_POSITION,
    selectable: false,
    getImageURL: (cardData: CardModel) => {
      return (
        "/assets/card_builder_assets/base_colors/" +
        cardData["aesthetic"] +
        "_base.png"
      );
    },
  },
  {
    elementName: "baseComponent" as keyof Pick<CardModel, "baseComponent">,
    elementPosition: BASE_POSITION,
    selectable: false,
    getImageURL: (cardData: CardModel) => {
      return cardData["baseComponent"];
    },
  },
  {
    elementName: "aesthetic" as keyof Pick<CardModel, "aesthetic">,
    elementPosition: AESTHETIC_POSITION,
    selectable: false,
    getImageURL: (cardData: CardModel) => {
      return (
        "/assets/card_builder_assets/aesthetic_icons/" +
        cardData["aesthetic"] +
        ".png"
      );
    },
  },
];

// Text elements
// New fonts should be added to src/app/card_builder/card_builder_utils/fontLoader.ts
export const textElements: TextElement[] = [
  {
    elementName: "name" as keyof Pick<CardModel, "name">,
    elementPosition: new ElementPosition(109, 68, 660, 197),
    textAttrs: {
      fontFamily: "Roboto",
      fontSize: 80,
      fontWeight: "700",
      shadow: { color: "rgba(0,0,0,0.1)", offsetX: -5, offsetY: 5, blur: 3 },
    },
    formAttrs: {},
    getDisplayElement: (elementValue: string) => elementValue,
  },
  {
    elementName: "characterOwner" as keyof Pick<CardModel, "characterOwner">,
    elementPosition: new ElementPosition(115, 190, 665, 210),
    textAttrs: {
      textAlign: "left",
      fontFamily: "Orbitron",
      fontSize: 16,
    },
    formAttrs: {},
    getDisplayElement: (elementValue: string) =>
      "character owner. " + elementValue,
  },
  {
    elementName: "species" as keyof Pick<CardModel, "species">,
    elementPosition: new ElementPosition(663, 124, 906, 220),
    textAttrs: {
      fontFamily: "Roboto",
      fontSize: 50,
      fontWeight: "700",
      textAlign: "right",
    },
    formAttrs: {},
    getDisplayElement: (elementValue: string) => elementValue,
  },
  {
    elementName: "vibe" as keyof Pick<CardModel, "vibe">,
    elementPosition: new ElementPosition(120, 795, 450, 841),
    textAttrs: {
      fontFamily: "Orbitron",
      fontSize: 32,
    },
    formAttrs: {},
    getDisplayElement: (elementValue: string) => elementValue,
  },
  {
    elementName: "gender" as keyof Pick<CardModel, "gender">,
    elementPosition: new ElementPosition(713, 813, 900, 836),
    textAttrs: {
      fontFamily: "Orbitron",
      textAlign: "right",
      fontSize: 24,
    },
    formAttrs: {},
    getDisplayElement: (elementValue: string) => elementValue,
  },
  {
    elementName: "illustrator" as keyof Pick<CardModel, "illustrator">,
    elementPosition: new ElementPosition(426, 1315, 930, 1341),
    textAttrs: {
      textAlign: "right",
      fontFamily: "Orbitron",
      fontSize: 16,
    },
    formAttrs: {},
    getDisplayElement: (elementValue: string) => "Illustrator: " + elementValue,
  },
  {
    elementName: "quote" as keyof Pick<CardModel, "quote">,
    elementPosition: new ElementPosition(75, 864, 530, 1280),
    textAttrs: {
      fontFamily: "Quattrocento Sans",
      fontSize: 24,
      textAlign: "center",
    },
    formAttrs: { multiline: true, rows: 3 },
    getDisplayElement: (elementValue: string) => elementValue,
  },
  {
    elementName: "bodyshape" as keyof Pick<CardModel, "bodyshape">,
    elementPosition: new ElementPosition(37, 20, 205, 72),
    textAttrs: {
      fontFamily: "Bayon",
      fontSize: 40,
      charSpacing: 160,
      textAlign: "center",
    },
    formAttrs: {},
    getDisplayElement: (elementValue: string) => elementValue,
  },
];

export const statElements: StatElement[] = [
  {
    elementName: "power" as keyof Pick<CardModel, "power">,
  },
  {
    elementName: "intelligence" as keyof Pick<CardModel, "intelligence">,
  },
  {
    elementName: "cuteness" as keyof Pick<CardModel, "cuteness">,
  },
  {
    elementName: "weirdness" as keyof Pick<CardModel, "weirdness">,
  },
  {
    elementName: "coolness" as keyof Pick<CardModel, "coolness">,
  },
  {
    elementName: "charmisma" as keyof Pick<CardModel, "charmisma">,
  },
];

export const cardBuilderDefaultState: CardModel = {
  name: "Your Name",
  species: "Your Species",
  vibe: "Your Vibe",
  gender: "Your Gender",
  illustrator: "Illustrator Name",
  quote: '"Your quote here" \n - you',
  cardArt: "/assets/badger.jpeg",
  baseImage: "/assets/card_builder_assets/base_colors/basic_base.png",
  baseComponent: COMPONENTS_PATH,
  bodyshape: "ANTHRO",
  power: 1,
  intelligence: 1,
  cuteness: 1,
  weirdness: 1,
  coolness: 1,
  charmisma: 1,
  aesthetic: "basic",
  characterOwner: "Character owner",
};

// export const SET_NUMBER_POSITION = new ElementPosition(97, 1306, 167, 1335);
// export const SET_NUMBER_ATTRS = {
//   fontFamily: "Sans",
//   fontSize: 16,
//   visible: false,
// };
