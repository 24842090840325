import React from "react";
import CardBuilder from "./cardBuilder";

export default function CardBuilderIndex(props: { fanMode: boolean }) {
  return (
    <>
      <CardBuilder fanMode={props.fanMode}></CardBuilder>
    </>
  );
}
