import { Grid } from "@mui/material";
import { SignupForm } from "../signupForm/signupForm";

export function AboutUsComponent() {
  return (
    <div style={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          {" "}
          <img
            height="auto"
            width="100%"
            src="/assets/images/landing_page_header.png"
            alt="A promotional image for Furcotra cards featuring 3 cards, the furcotra logo, and the text 'Furry trading cards!'"
          ></img>
        </Grid>
        <Grid item xs={4}>
          <div>
            {" "}
            <h1>About Furcotra Cards</h1>
            <div>
              <p>
                Furry trading cards? Furry trading cards. Have you ever wanted
                to see YOUR fursona/OC on a real, physical trading card? Well
                now you can! Furcotra cards are a way to show off your
                character's unique personality on a pocket-sized collectable
              </p>
              <p>
                What are Furcotra cards? They're collectable trading cards
                displaying a wide variety of art of animal-based characters.
                Furcotra cards feature art from a variety of artists.
              </p>
              <p>More information coming soon!</p>
            </div>
          </div>
        </Grid>

        <Grid item xs={3}>
          <SignupForm></SignupForm>
        </Grid>
        <Grid item xs={3}>
          {" "}
          <img
            height="auto"
            width="100%"
            src="/assets/card_set_1/chris.png"
            alt="An example of a furcotra card. This is a trading card with a blue background featuring art of an anthropomorphic tanuki/wolf named 'Chris'"
          ></img>
        </Grid>
        <Grid item xs={3}>
          {" "}
          <img
            height="auto"
            width="100%"
            src="/assets/card_set_1/talie.png"
            alt="An example of a furcotra card. This is a trading card with a pink background featuring art of an anthropomorphic pink red panda named 'Talie'"
          ></img>
        </Grid>
        <Grid item xs={3}>
          {" "}
          <img
            height="auto"
            width="100%"
            src="/assets/card_set_1/orion.png"
            alt="An example of a furcotra card. This is a trading card with a purple background featuring art of a humaoid creature named 'Orion'"
          ></img>
        </Grid>
      </Grid>
    </div>
  );
}
