import React from "react";
import { topBannerStyle, bannerTextStyle } from "./topBannerStyle";

function TopBannerComponent() {
  return (
    <>
      <div style={topBannerStyle}>
        <img
          src="assets/images/logo.png"
          alt="Furcotra Logo"
          style={{ width: "150px", padding: "15px" }}
        ></img>
        <h1 style={bannerTextStyle}>
          Furcotra <span style={{ fontSize: "30px" }}>Cards</span>
        </h1>
      </div>
    </>
  );
}

export default TopBannerComponent;
