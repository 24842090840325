/* istanbul ignore file */

import { ElementPosition } from "./elementPosition";

const fabric = require("fabric").fabric;

/**
 * Utility function to refactor the callback-based fabric.Image.fromURL function
 * into a promise.
 *
 * @param url URL or path to the image
 * @param imageOptions Image options to be passed to fabric.Image.fromURL
 * @returns
 */
const promiseFromUrl = (url: string, imageOptions = {}) => {
  return new Promise((res, rej) => {
    const callback = (image: any, isError: boolean) => {
      if (isError) rej(new Error("An error occurred loading image from URL"));
      else res(image);
    };
    fabric.Image.fromURL(url, callback, imageOptions);
  });
};

/**
 * This method is async because the images need to be
 * loaded from the URL (path) to the image
 *
 * @param canvas The canvas object
 * @param path path/url to the image
 * @param elementPosition Location on the card to render the image
 * @param selectable Whether the use can select/drag/drop the image on the canvas
 * @returns
 */
export async function addImage(
  canvas: any,
  path: string,
  elementPosition: ElementPosition,
  selectable: boolean,
  elementName: string
) {
  return promiseFromUrl(path, {}).then((oImg: any) => {
    oImg
      .set("left", elementPosition.topleft_x)
      .set("top", elementPosition.topleft_y);
    oImg.set("selectable", selectable);
    canvas.add(oImg);
  });
}
