import { SignupForm } from "../signupForm/signupForm";

export function ContactComponent() {
  return (
    <>
      <h1>Contact us</h1>
      <p>
        Questions? Comments? Email us at <b>furcotratcg[at]gmail.com</b>
      </p>
      <h1>Want to get involved?</h1>
      <p>
        If you are interested in getting involved in making furry trading cards,
        then please reach out!
      </p>
      <p>We are in search of the following:</p>
      <ul>
        <li>Artists</li>
        <li>Graphic Designers</li>
        <li>Furry influencers/promoters</li>
        <li>Commercial Printing</li>
      </ul>
      <p>
        Please email <b>furcotratcg[at]gmail.com</b> with the subject line
        "Getting involved" if you are interested!
      </p>
      <SignupForm></SignupForm>
    </>
  );
}
