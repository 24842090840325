/* istanbul ignore file */
import { CardModel } from "../../../common/cardModel";
import { asyncForEach } from "../../../common/utils/commonUtils";
import {
  BASE_POSITION,
  ImageElement,
  imageElements,
  TextElement,
  textElements,
  StatElement,
  statElements,
  CANVAS_SCALE,
} from "../cardBuilderConfig";
import { useFabric } from "../card_builder_utils/fabricUtils";
import { addImage } from "../card_builder_utils/imageUtils";
import { addStatbar, statBuilder } from "../card_builder_utils/statUtils";
import { addText } from "../card_builder_utils/textUtils";

interface CanvasElement {
  render: (canvas: any) => Promise<void>;
}

export function buildAsyncElementsToRender(cardData: CardModel) {
  let imageElementsToRender = imageElements.map(
    (image_element: ImageElement) => {
      return {
        render: async (canvas: any) => {
          return await addImage(
            canvas,
            image_element.getImageURL(cardData),
            image_element.elementPosition,
            image_element.selectable,
            image_element.elementName
          );
        },
      };
    }
  );

  let statElementsToRender = statElements.map((stat_element: StatElement) => {
    return {
      render: async (canvas: any) => {
        let statName = stat_element.elementName;
        let statValue = cardData[statName as keyof CardModel] as number;
        return await addStatbar(canvas, statBuilder(statName, statValue));
      },
    };
  });
  let elementsToRender: CanvasElement[] = [];
  return elementsToRender.concat(imageElementsToRender, statElementsToRender);
}

/**
 * This procedure generates elements to be rendered to the canvas (or to the DOM in unittests)
 * @param cardData
 */
export function buildSyncElementsToRender(
  // cardImagePath: string,
  cardData: CardModel
): CanvasElement[] {
  return textElements.map((text_element: TextElement) => {
    let elementName = text_element.elementName;
    return {
      render: (canvas: any) => {
        return addText(
          canvas,
          text_element.getDisplayElement(
            cardData[elementName as keyof CardModel] as string
          ),
          text_element.elementPosition,
          text_element.textAttrs
        );
      },
    };
  });
}

// Important that we render some elements first and in the correct order, otherwise they will cover up elements if rendered later
async function renderElementsInOrder(
  imageElementsToRender: CanvasElement[],
  syncElementsToRender: CanvasElement[],
  canvas: any
) {
  await asyncForEach(imageElementsToRender, async (element) => {
    await element.render(canvas);
  });
  syncElementsToRender.forEach(async (element) => {
    element.render(canvas);
  });
}

export function Canvas(props: {
  asyncElementsToRender: CanvasElement[];
  syncElementsToRender: CanvasElement[];
}) {
  const ref = useFabric((fabricCanvas: any) => {
    renderElementsInOrder(
      props.asyncElementsToRender,
      props.syncElementsToRender,
      fabricCanvas
    );
    // fabricCanvas.setZoom(CANVAS_SCALE);
  });
  return (
    <>
      <div
        style={{
          display: "inline-block",
          width: BASE_POSITION.width * CANVAS_SCALE + "px",
          height: BASE_POSITION.height * CANVAS_SCALE + "px",
          transform: `scale(${CANVAS_SCALE}) translateX(-${
            BASE_POSITION.width * CANVAS_SCALE * 0.5
          }px) translateY(-${BASE_POSITION.height * CANVAS_SCALE * 0.5}px)`,
        }}
      >
        <canvas
          ref={ref}
          width={BASE_POSITION.width}
          height={BASE_POSITION.height}
        />
      </div>
    </>
  );
}
