import { Button, Stack } from "@mui/material";
import React, { useEffect, useReducer, useState } from "react";
import { CardBuilderContext } from "./cardBuilderContexts";
import { CardBuilderDisplay } from "./card_builder_display/cardBuilderDisplay";
import { cardBuilderStateReducer } from "./cardBuilderReducers";
import { CardBuilderForm } from "./card_builder_form/cardBuilderForm";
import { cardBuilderDefaultState } from "./cardBuilderConfig";
import CardBuilderInfo from "./card_builder_info";
import { loadFonts } from "../../common/utils/fontLoader";

export default function CardBuilder(props: { fanMode: boolean }) {
  const [state, dispatch] = useReducer(
    cardBuilderStateReducer,
    cardBuilderDefaultState
  );

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = state.name + "_card.png";
    let canvas: HTMLCanvasElement = document.getElementsByClassName(
      "lower-canvas"
    )[0] as HTMLCanvasElement;
    link.href = canvas.toDataURL();
    link.click();
  };
  return (
    <>
      <Stack direction="row">
        <CardBuilderContext.Provider value={[state, dispatch]}>
          <Stack direction="column">
            <CardBuilderDisplay
              cardState={state}
              fanMode={props.fanMode}
            ></CardBuilderDisplay>
            <Button onClick={onDownload} variant="contained" color="primary">
              Download card
            </Button>
          </Stack>
          <CardBuilderForm></CardBuilderForm>
        </CardBuilderContext.Provider>
        <CardBuilderInfo></CardBuilderInfo>
      </Stack>
    </>
  );
}
