import { SignupForm } from "../signupForm/signupForm";

export function KickstarterComponent() {
  return (
    <>
      <h1>Kickstarter coming soon!</h1>
      <p>
        To be notified of when the Kickstarter launches, subscribe to our
        newsletter below!
      </p>
      <SignupForm></SignupForm>
    </>
  );
}
