import { Box, Stack } from "@mui/material";
import React from "react";
import { BLACK } from "../../../common/style/colors";
import { NavButton } from "./navButtonComponent";
import {
  // DiscordIcon,
  FurAffinityIcon,
  // InstagramIcon,
  // RedditIcon,
  TelegramIcon,
  // TumblrIcon,
  TwitterIcon,
} from "./socialMediaButtonComponent";

export function NavBarComponent(props: {}) {
  return (
    <>
      <Box
        style={{
          padding: "15px",
          paddingBottom: "1rem",
          background: BLACK,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <nav>
          <NavButton label="About Us" linkTo="/"></NavButton>
          {/* <NavButton label="Cards" linkTo="/cards"></NavButton> */}
          <NavButton label="Contact" linkTo="/contact"></NavButton>
          <NavButton label="Kickstarter" linkTo="/kickstarter"></NavButton>
          <NavButton
            label="Make your own card"
            linkTo="/cardBuilder"
          ></NavButton>{" "}
        </nav>
        <Stack direction="row" spacing={2}>
          <TwitterIcon></TwitterIcon>
          {/* <TumblrIcon></TumblrIcon> */}
          {/* <InstagramIcon></InstagramIcon> */}
          {/* <RedditIcon></RedditIcon> */}
          <TelegramIcon></TelegramIcon>
          {/* <DiscordIcon></DiscordIcon> */}
          <FurAffinityIcon></FurAffinityIcon>
        </Stack>
      </Box>
    </>
  );
}
