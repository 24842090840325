import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";

import AboutUsIndex from "./app/aboutUs";
import ContactIndex from "./app/contact";
import KickstarterIndex from "./app/kickstarter";
import CardBuilderIndex from "./app/card_builder";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<AboutUsIndex></AboutUsIndex>}></Route>
          <Route path="contact" element={<ContactIndex></ContactIndex>}></Route>
          <Route
            path="kickstarter"
            element={<KickstarterIndex></KickstarterIndex>}
          ></Route>
          {/* <Route
            path="cards"
            element={
              <CardCollectionComponent
                currentCardSet={HOME_FUREE_DATA}
                setList={SETLIST_DATA}
              ></CardCollectionComponent>
            }
          ></Route> */}
          <Route
            path="cardBuilder"
            element={<CardBuilderIndex fanMode={false}></CardBuilderIndex>}
          ></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
