/* istanbul ignore file */
import React, { useEffect, useState } from "react";
import "./index.css";
import MainComponent from "./app/main/index";
import { loadFonts } from "./common/utils/fontLoader";

function App() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    loadFonts().then(() => setLoading(false));
  });
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <MainComponent></MainComponent>
    </>
  );
}

export default App;
