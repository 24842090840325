import { addImage } from "./imageUtils";

import { ElementPosition } from "./elementPosition";

import {
  Stat,
  StatEnum,
  StatEnumInterface,
  StatIdx,
  StatIdxInterface,
} from "../../../common/cardModel";
import {
  ENDPIECE_WIDTH,
  MAX_STAT_VALUE,
  MIDPIECE_WIDTH,
  SPACE_BETWEEN_STAT_BARS,
  STAT_BAR_HEIGHT,
  TOP_BAR_STARTING_X,
  TOP_BAR_STARTING_Y,
} from "../cardBuilderConfig";

export interface StatSegmentToRender {
  path: string;
  elementPosition: ElementPosition;
  elementName: string;
}

/**
 *
 * Given the name of the stat and the idx of the segment (idx determines if we want a midpiece or end piece), return the path to the corresponding
 * image of the segment piece.
 *
 * @param statName Name of the stat
 * @param i idx of this stat segment (to determine if we need to load end piece)
 * @returns path to the correct asset
 */
function pathToStatSegmentImage(statName: string, i: number): string {
  // MAX_STAT_VALUE - 2: the 2 is because of: 1. 0-based indexing versus 1-based indexing and 2. we start with 1 filled bar always
  const mid_or_end = i === MAX_STAT_VALUE - 2 ? "end" : "mid";
  return (
    "assets/card_builder_assets/stat_icons/" + // TODO: find a better place to store this configuration
    statName +
    "_filled_" +
    mid_or_end +
    ".png"
  );
}

/**
 * Given a stat name and value, return an array of the stat segments to be rendered to the cardbuilder
 * @param statName
 * @param statVal
 * @returns
 */
export function statBuilder(
  statName: string,
  statVal: number
): StatSegmentToRender[] {
  const shortStatName = StatEnum[statName as keyof StatEnumInterface];
  const barIdx = StatIdx[shortStatName as keyof StatIdxInterface];
  let statBarsToRender = [];
  const y_pos =
    TOP_BAR_STARTING_Y + barIdx * (STAT_BAR_HEIGHT + SPACE_BETWEEN_STAT_BARS);
  for (let i = 0; i < statVal - 1; i++) {
    let x_pos = TOP_BAR_STARTING_X + i * MIDPIECE_WIDTH;
    let width = i === MAX_STAT_VALUE - 2 ? ENDPIECE_WIDTH : MIDPIECE_WIDTH;

    statBarsToRender.push({
      path: pathToStatSegmentImage(shortStatName, i),
      elementPosition: new ElementPosition(
        x_pos,
        y_pos,
        x_pos + width,
        y_pos + STAT_BAR_HEIGHT
      ),
      elementName: statName,
    });
  }
  return statBarsToRender;
}

/**
 * Given an array of stat segments, render them to the canvas. This method is async
 * because the images need to be loaded from the URL (path) to the image
 *
 * @param canvas The canvas
 * @param statBarToRender Array of stat segments to render
 * @returns
 */
/* istanbul ignore next */ // Simple procedure, hard to test, just ignore in coverage
export async function addStatbar(
  canvas: any,
  statBarToRender: StatSegmentToRender[]
) {
  let idx = 1;
  return statBarToRender.forEach((statSegment: StatSegmentToRender) => {
    idx += 1;
    return addImage(
      canvas,
      statSegment.path,
      statSegment.elementPosition,
      false,
      statSegment.elementName + idx
    );
  });
}
