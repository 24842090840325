import React from "react";
import { KickstarterComponent } from "./kickstarterComponent";

export default function KickstarterIndex(props: {}) {
  return (
    <>
      <KickstarterComponent></KickstarterComponent>
    </>
  );
}
